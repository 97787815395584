
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Slide from './../../../types/Slide';
import Slider from './../../../components/Slider.vue';
import SectionTitle from './../../../components/SectionTitle.vue';
import {
  imgClientAlmokawloonAlarab,
  imgClientArcplanGroup,
  imgClientAlsharq,
  imgClientAjiad,
  imgClientPremcoPrecast,
} from './../../../utils/images';

export default defineComponent({
  components: { Slider, SectionTitle },
  setup() {
    const { t } = useI18n();
    const list: Slide[] = [
      {
        activeStatus: true,
        title: t('home.projects.cards.title2'),
        description: '',
        image: imgClientAlmokawloonAlarab,
      },
      {
        activeStatus: false,
        title: t('home.projects.cards.title3'),
        description: '',
        image: imgClientArcplanGroup,
      },
      {
        activeStatus: false,
        title: t('home.projects.cards.title4'),
        description: '',
        image: imgClientAlsharq,
      },
      {
        activeStatus: false,
        title: t('home.projects.cards.title5'),
        description: '',
        image: imgClientAjiad,
      },
      {
        activeStatus: false,
        title: t('home.projects.cards.title6'),
        description: '',
        image: imgClientPremcoPrecast,
      },
    ];

    return { t, list };
  },
});
