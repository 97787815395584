import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = { class: "btn-link animated-item animate__slower animate__delay-1s" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('vision.header'),
      description: _ctx.t('vision.description'),
      image: _ctx.imgVision,
      height: "170px",
      width: "80%",
      class: "as-card mb-5 centered img-circle content-centered reverse animated-item animate__slow",
      imgWidth: "10%",
      txtWidth: "85%"
    }, null, 8, ["title", "description", "image"]),
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('mision.header'),
      description: _ctx.t('mision.description'),
      image: _ctx.imgMision,
      height: "170px",
      width: "80%",
      class: "as-card mb-5 centered img-circle content-centered animated-item animate__slower",
      imgWidth: "10%",
      txtWidth: "85%"
    }, null, 8, ["title", "description", "image"]),
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('value.header'),
      description: _ctx.t('value.description'),
      image: _ctx.imgValue,
      height: "220px",
      width: "80%",
      class: "as-card mb-5 centered img-circle content-centered reverse animated-item animate__slow animate__delay-1s",
      imgWidth: "10%",
      txtWidth: "85%"
    }, null, 8, ["title", "description", "image"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_b_button, {
        pill: "",
        variant: "outline-success"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, { to: { name: 'ContactUs' } }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('navbar.ContactUs')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}