
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Slide from './../../../types/Slide';
import Slider from './../../../components/Slider.vue';
import SectionTitle from './../../../components/SectionTitle.vue';
import {
  imgworkplace,
  imgPhoneApps,
  imgWeb,
  imgDesign,
  imgSEO,
  imgEmbedded,
  imgHosting,
  imgCustomerService,
} from './../../../utils/images';

export default defineComponent({
  components: { Slider, SectionTitle },
  setup() {
    const { t } = useI18n();
    const list: Slide[] = [
      {
        activeStatus: true,
        title: t('servicesPage.slider.title1'),
        description: '',
        image: imgworkplace,
      },
      {
        activeStatus: false,
        title: t('servicesPage.slider.title2'),
        description: '',
        image: imgPhoneApps,
      },
      {
        activeStatus: false,
        title: t('servicesPage.slider.title3'),
        description: '',
        image: imgWeb,
      },
      {
        activeStatus: false,
        title: t('servicesPage.slider.title4'),
        description: '',
        image: imgDesign,
      },
      {
        activeStatus: false,
        title: t('servicesPage.slider.title5'),
        description: '',
        image: imgSEO,
      },
      {
        activeStatus: false,
        title: t('servicesPage.slider.title7'),
        description: '',
        image: imgEmbedded,
      },
      {
        activeStatus: false,
        title: t('servicesPage.slider.title8'),
        description: '',
        image: imgHosting,
      },
      {
        activeStatus: false,
        title: t('servicesPage.slider.title9'),
        description: '',
        image: imgCustomerService,
      },
    ];

    return { t, list };
  },
});
