import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b196754"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "aboutus-aphorism" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_Aphorism = _resolveComponent("Aphorism")!
  const _component_OurServicesSlider = _resolveComponent("OurServicesSlider")!
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_CustomCards = _resolveComponent("CustomCards")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('home.slider.title1'),
      description: _ctx.t('footer.bio'),
      image: _ctx.imgAboutUsHeader,
      txtPadding: "5rem",
      scale: "0.5",
      height: "200px"
    }, null, 8, ["title", "description", "image"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Aphorism, {
        name: _ctx.t('home.aboutUs.aphorism.name'),
        title: _ctx.t('home.aboutUs.aphorism.title'),
        text: _ctx.t('home.aboutUs.aphorism.text'),
        image: _ctx.imgEmployeeMohammedOkasha
      }, null, 8, ["name", "title", "text", "image"])
    ]),
    _createVNode(_component_OurServicesSlider),
    _createVNode(_component_SectionTitle, {
      title: _ctx.t('home.aboutUs.cards.header')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CustomCards, {
        list: _ctx.CustomCardsList,
        imgWidth: "100%",
        imgHeight: "350px"
      }, null, 8, ["list"])
    ])
  ]))
}