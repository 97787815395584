
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import SectionHeader from './../../../components/SectionHeader.vue';
import { imgMision, imgVision, imgValue } from './../../../utils/images';

export default defineComponent({
  components: { SectionHeader },
  methods: {
    addAnimation() {
      window.addEventListener(
        'scroll',
        function () {
          const scrollLimit = 100;
          const animatedItems = document.querySelectorAll(
            '.animated-item',
            // eslint-disable-next-line no-undef
          ) as NodeListOf<Element> | [];

          animatedItems.forEach((animatedItem: Element) => {
            if (animatedItem != null) {
              if (
                document.body.scrollTop > scrollLimit ||
                document.documentElement.scrollTop > scrollLimit
              ) {
                animatedItem.classList.add('animate__animated');
                animatedItem.classList.add('animate__fadeInUp');
              } else {
                animatedItem.classList.remove('animate__animated');
                animatedItem.classList.remove('animate__fadeInUp');
              }
            }
          });
        },
        true,
      );
    },
  },
  created() {
    this.addAnimation();
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      imgMision,
      imgVision,
      imgValue,
    };
  },
});
