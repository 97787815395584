import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "about" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slider = _resolveComponent("Slider")!
  const _component_MisionVision = _resolveComponent("MisionVision")!
  const _component_Managers = _resolveComponent("Managers")!
  const _component_OurClientsSlider = _resolveComponent("OurClientsSlider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Slider),
    _createVNode(_component_MisionVision, { class: "" }),
    _createVNode(_component_Managers),
    _createVNode(_component_OurClientsSlider)
  ]))
}