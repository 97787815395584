
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Slide from './../../../types/Slide';
import Slider from './../../../components/Slider.vue';

import {
  imgThinkingOutOfBox,
  imgAboutS1,
  imgAboutS2,
  imgAboutS3,
  imgAboutS4,
} from './../../../utils/images';

export default defineComponent({
  components: { Slider },
  setup() {
    const { t } = useI18n();
    const list: Slide[] = [
      {
        activeStatus: true,
        title: t('home.aboutUs.aphorism.text'),
        description: '',
        image: imgAboutS4,
      },
      {
        activeStatus: false,
        title: t('home.aboutUs.aphorism.text'),
        description: '',
        image: imgAboutS3,
      },
      {
        activeStatus: false,
        title: t('home.aboutUs.aphorism.text'),
        description: '',
        image: imgAboutS2,
      },
      {
        activeStatus: false,
        title: t('home.aboutUs.aphorism.text'),
        description: '',
        image: imgAboutS1,
      },
      {
        activeStatus: false,
        title: t('home.aboutUs.aphorism.text'),
        description: '',
        image: imgThinkingOutOfBox,
      },
    ];

    return { list };
  },
});
