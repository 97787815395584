import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_Slider = _resolveComponent("Slider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SectionTitle, {
      title: _ctx.t('home.services.header.title')
    }, null, 8, ["title"]),
    _createVNode(_component_Slider, {
      id: "OurServicesSlider",
      list: _ctx.list,
      class: "centered-txt"
    }, null, 8, ["list"])
  ], 64))
}