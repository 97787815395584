
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Slider from './components/Slider.vue';
import MisionVision from './components/MisionVision.vue';
import Managers from './components/Managers.vue';
import OurClientsSlider from './components/OurClientsSlider.vue';
import { scrollToTop } from './../../utils/helper';

export default defineComponent({
  components: {
    Slider,
    MisionVision,
    Managers,
    OurClientsSlider,
  },
  created() {
    scrollToTop();
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
});
